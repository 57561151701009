import {
  Headings,
  Button,
  Image,
  Paragraph,
  Label,
  Loader,
} from '@sentaraui/optimahealth_web';
import { useState, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import manageMedication from '../../assets/images/manageMedication.png';
import prescriptionDelivery from '../../assets/images/prescriptionDelivery.png';
import pharmacy from '../../assets/images/pharmacy.png';
import pharmacyMedication from '../../assets/images/pharmacyMedication.png';
import {
  virtualVisitUrl,
  errorhandler,
  navigateToExternalUrl,
  prescriptionDrugs,
  expressScript,
  preAuthorization,
  pharmacyReimbursement,
  SPECIALTY_PHARMAC_URL,
  PRESCRIPTION_DELIVERY_URL,
} from 'common';
import { useMySSOUrl } from '@sentara/sentara-api-hooks-core';
import { GlobalContext } from 'context';
import { CareTile } from 'templates';

function PharmacyTools() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loader, setLoader] = useState<boolean>(false);
  const { SSOUrlType } = useMySSOUrl();
  const { getFeature } = useContext(GlobalContext);

  const learMore = async () => {
    const url = getFeature?.PharmacyBenefitsManager
      ? 'EsiRx?source=orderrx'
      : 'OptumRx';

    setLoader(true);
    const res = await SSOUrlType(url);
    if (res?.data?.ssoUrl) {
      navigateToExternalUrl(res?.data?.ssoUrl);
    } else if (res?.errorCode) {
      errorhandler(res?.errorCode);
    }
    setLoader(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      navigate(virtualVisitUrl);
    }
  };

  return (
    <div>
      {loader && <Loader dataTestId="loader" />}
      <div
        data-testid="back"
        className="d-flex flex-row align-items-center cursor-pointer"
        onClick={() => navigate(virtualVisitUrl)}
        role="button"
        tabIndex={0}
        onKeyDown={handleKeyDown}
      >
        <span className="arrow-back-black" />
        <Headings
          level={1}
          text={t('pharmacyTools.label.title')}
          dataTestId="PharmacyToolsHeader"
        />
      </div>
      <div className="d-flex flex-column my-2">
        <div className="row py-4">
          <div className="col-md-6">
            <Image
              dataTestId={'managemedicationImage'}
              className="rounded-4 img-fluid"
              path={manageMedication}
            />
          </div>
          <div className="d-flex flex-column col-md-6 justify-content-between mt-3 mt-md-0">
            <Headings
              level={2}
              variant={'semiBold'}
              text={t('pharmacyTools.content.manageYourMedication')}
            />
            <div className="mt-3">
              <Paragraph dataTestId={'medicationContent'}>
                {t('pharmacyTools.content.medicationContent')}
              </Paragraph>

              <ul className="custom-list">
                <li>
                  <Label>
                    {t('pharmacyTools.content.prescriptionRefills')}
                  </Label>
                  <span>{t('pharmacyTools.content.orderRefills')}</span>
                </li>
                <li>
                  <Label>
                    {t('pharmacyTools.content.medicationSynchronization')}
                  </Label>
                  <span>
                    {t('pharmacyTools.content.medicationSynchronization2')}
                  </span>
                </li>
                <li>
                  <Label>{t('pharmacyTools.content.personalizedAdvice')}</Label>
                  <span> {t('pharmacyTools.content.personalizedAdvice2')}</span>
                </li>
              </ul>

              <div className="d-flex align-items-center">
                <Button
                  className="btn-links pe-3"
                  onClick={() => learMore()}
                  dataTestId="learnMore"
                >
                  {t('pharmacyTools.label.learMore')}
                </Button>
                <span className="arrow-right" />
              </div>
            </div>
          </div>
        </div>
        <div className="row pb-4 pt-0 pt-md-4">
          <div className="col-md-6 justify-content-between order-md-first mt-3 mt-md-0">
            <Headings
              level={2}
              variant={'semiBold'}
              text={t('pharmacyTools.content.prescriptionDelivery')}
            />
            <Paragraph dataTestId={'prescriptionDeliveryContent'}>
              {t('pharmacyTools.content.prescriptionDeliveryContent')}
            </Paragraph>
            <div className="d-flex align-items-center">
              <Button
                className="btn-links pe-3"
                onClick={() => navigateToExternalUrl(PRESCRIPTION_DELIVERY_URL)}
              >
                {t('pharmacyTools.label.learMore')}
              </Button>
              <span className="arrow-right" />
            </div>
          </div>
          <div className="col-md-6 order-first">
            <Image
              dataTestId={'prescriptionDeliveryImage'}
              className="rounded-4 img-fluid"
              path={prescriptionDelivery}
            />
          </div>
        </div>

        <div className=" grid-cont plan-grid-cont mx-0 mx-md-1  d-flex gap-3 search-pharmacy">
          <div className=" h-100 flex-1 search-content">
            <div className="row m-0  h-100">
              <div className="col-md-6 col-lg-6 col-xs-12 p-0 h-100 ">
                <Image
                  dataTestId={'pharmacySearchImage'}
                  path={pharmacy}
                  className="img-fluid w-100"
                />
              </div>
              <div className="col-md-6 col-lg-6 col-xs-12 p-4 px-0 px-md-1 px-xl-4 h-100">
                <Headings
                  level={2}
                  variant={'medium'}
                  className={'font-twentyfour'}
                  text={t('searchPharamcy.label.searchPharamacy')}
                />
                <Paragraph dataTestId={'pharmacyToolsContent'}>
                  {t('searchPharamcy.content.physicalTherapyContent')}
                </Paragraph>
                <div className="d-flex align-items-center">
                  <Button
                    className="btn-links pe-3"
                    dataTestId={'expressScript'}
                    onClick={() => {
                      navigateToExternalUrl(expressScript);
                    }}
                  >
                    {t('searchPharamcy.content.continue')}
                  </Button>
                  <span className="arrow-right" />
                </div>
              </div>
            </div>
          </div>
          <div className=" h-100  flex-1 search-content">
            <div className="row  m-0 h-100">
              <div className="col-md-6 col-lg-6 col-xs-12 p-0">
                <Image
                  dataTestId={'pharmacyMedicationImage'}
                  className="img-fluid w-100"
                  path={pharmacyMedication}
                />
              </div>
              <div className="col-md-6 col-lg-6 col-xs-12 p-4 px-0 px-md-1 px-xl-4 d-flex flex-column gap-2">
                <Headings
                  level={2}
                  variant={'medium'}
                  className={'font-twentyfour'}
                  text={t('searchPharamcy.label.saveMedication')}
                />

                <div className="d-flex align-items-center">
                  <Button
                    className="btn-links"
                    dataTestId={'prescriptionDrugs'}
                    onClick={() => {
                      navigateToExternalUrl(prescriptionDrugs);
                    }}
                  >
                    {t('searchPharamcy.content.viewDrug')}
                  </Button>
                  <span className="arrow-right" />
                </div>
                <div className="d-flex align-items-center">
                  <Button
                    className="btn-links"
                    dataTestId={'preAuthorization'}
                    onClick={() => navigateToExternalUrl(preAuthorization)}
                  >
                    {t('searchPharamcy.content.preAuthorization')}
                  </Button>
                  <span className="arrow-right" />
                </div>
                <div className="d-flex align-items-center">
                  <Button
                    className="btn-links text-start"
                    dataTestId={'PharmacyReimbursement'}
                    onClick={() => navigateToExternalUrl(pharmacyReimbursement)}
                  >
                    {t('searchPharamcy.content.pharmacyReimbursement')}
                  </Button>
                  <span className="arrow-right arrow-back-pharmacy" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          data-testid="historyTilesId"
          className="row row-cols-md-3 mt-3 mx-4 mx-md-0"
        >
          <CareTile
            className="caretile-border-bottom"
            fontAwesomeIconKey="notes-medical"
            heading={t('pharmacyTools.label.orderHistory')}
            subHeading={t('pharmacyTools.content.orderHistory') ?? ''}
            onClick={() => learMore()}
          />

          <CareTile
            className="caretile-border-bottom"
            fontAwesomeIconKey="file-invoice-dollar"
            heading={t('pharmacyTools.label.claimHistory')}
            subHeading={t('pharmacyTools.content.claimsHistory') ?? ''}
            onClick={() => learMore()}
          />
          <CareTile
            fontAwesomeIconKey="capsules"
            heading={t('pharmacyTools.label.specialtyPharmacy')}
            subHeading={t('pharmacyTools.content.specialtyPharmacy') ?? ''}
            onClick={() => navigateToExternalUrl(SPECIALTY_PHARMAC_URL)}
          />
        </div>
      </div>
    </div>
  );
}
export default PharmacyTools;
