import {
  HeaderLogo,
  ContactUsLink,
  SearchLink,
  HamburgerMenuLink,
  FooterDisclaimer,
} from 'organisms';
import { useState, useEffect, useContext } from 'react';
import { useResetPassword } from '@sentara/sentara-api-hooks-core';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getMaxLength, handleKeyDownUsername, signIn } from 'common';
import ResetPassword from './ResetPassword';
import {
  Label,
  ErrorMessage,
  Button,
  CheckIconBig,
  Headings,
  InputField,
  GlobalError,
  Loader,
  Link,
} from '@sentaraui/optimahealth_web';
import { GlobalContext } from '../../context';

import SecurityQuestion from './SecurityQuestion';
interface FormData {
  userNameLabelForgotPassword: string;
  password?: string;
}

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [retriveSuccess, setRetriveSuccess] = useState<boolean>(false);
  const { state, resetPassword } = useResetPassword();
  const navigate = useNavigate();
  const { getFeature } = useContext(GlobalContext);
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm({}); /* useForm props extract */
  useEffect(() => {
    if (state?.error) {
      setErrorMessage(true);
    }
    document.title = t('pageTitle.label.ForgotPassword');
  }, [state?.error]);

  /* to get params from URL */
  let search = window.location.search;
  let newParams = new URLSearchParams(search);
  let param: string = newParams.get('token') || '';

  let params: string = newParams.get('token') || '';
  let decodedParam = decodeURI(params);
  // when %20 is decoded to Space and sent to the Reset password API its throwing 462 error so converted to Plus sign as per legacy
  params = decodedParam.replace(/ /g, '+');

  /* oncancel click navigate to sign in */
  const cancelClick = () => {
    navigate(signIn);
  };

  const onSubmit = async (data: any) => {
    const requestData = {
      user: { userId: data.userNameLabelForgotPassword },
      channel: 'Web',
      lang: 'en',
    };
    const response = await resetPassword(requestData);
    if (response.error) {
      setErrorMessage(true);
    } else if (response?.data === '') {
      setRetriveSuccess(true);
    }
  };

  return (
    <div className="d-flex flex-column h-100">
      <div>
        <header>
          <div className="container-fluid">
            <div>
              <nav className="d-flex align-items-center justify-content-between pt-3 mb-4">
                <div>
                  <HeaderLogo />
                </div>
                <div>
                  <div className="d-flex flex-no-wrap align-items-center">
                    <div className="d-none d-xl-block d-lg-block d-md-none ms-4">
                      <ContactUsLink showIcon={false} />
                    </div>
                    <div className="d-lg-none d-md-block d-sm-block">
                      <SearchLink />
                    </div>
                    <div className="d-lg-none d-md-block d-sm-block">
                      <HamburgerMenuLink showLogout={false} />
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
          <hr className="border-w-2" />
        </header>
        {/* Breadcrumb */}
        <div className="container-fluid pt-4" role="main">
          <div className="row align-items-center justify-content-center">
            {/* Reset password component */}
            {param && (
              <>
                {<ResetPassword params={params} />}
              </>
            )}
            {!param && (
              <div className="col-lg-6 col-md-9 col-sm-12 col-12 mb-3">
                {!retriveSuccess ? (
                  <form onSubmit={handleSubmit(onSubmit)} method="post">
                    {state?.loading && <Loader />}
                    {errorMessage ? (
                      <GlobalError
                        variant="mb-3"
                        dataTestId="usernameServiceErr12"
                      >
                        {t('forgotPassword.message.usernameServiceErr2')}
                      </GlobalError>
                    ) : null}
                    <div className="pb-5">
                      <Headings
                        level={1}
                        text={t('forgotPassword.label.passwordHelp')}
                        dataTestId="userNameForgot"
                      />
                    </div>

                    <div className="mb-4">
                      <Label
                        className="pb-1"
                        dataTestId={'userNameLabelForgotPassword'}
                        htmlFor="userNameLabelForgotPassword​"
                        children={t(
                          'forgotPassword.message.userNameValidationText'
                        )}
                      />
                      <div className="pos-rel">
                        <InputField
                          placeholder={t(
                            'activation.label.userNameSmallPlaceholder'
                          )}
                          maxLength={getMaxLength.userName}
                          data-testid="userInputForgotPassword"
                          className={
                            errors.userNameLabelForgotPassword
                              ? 'error-border form-input'
                              : 'form-input'
                          }
                          type="text"
                          id="userNameLabelForgotPassword"
                          {...register('userNameLabelForgotPassword', {
                            required: true,
                          })}
                          onKeyDown={handleKeyDownUsername}
                          onFocus={() => {
                            setErrorMessage(false);
                          }}
                        />
                        {errors.userNameLabelForgotPassword && (
                          <div className="mt-1">
                            <ErrorMessage
                              children={t('forgetUser.label.enterUserName')}
                              data-testid={'forgotPasswordErrorMessage'}
                            />
                          </div>
                        )}
                      </div>
                      <div className="small pt-3">
                        {t('forgotPassword.label.didYou')}{' '}
                        <Link
                          to={'/login/forgot-username'}
                          data-testid="forgotUsernameLink"
                          className="text-decoration-underline"
                          aria-label="Forgot username"
                        >
                          {t('forgotPassword.label.forgetUserNameText')}
                        </Link>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-auto col-md-auto col-sm12 col-12 mb-4">
                        <Button
                          dataTestId="submitButton"
                          type="submit"
                          variant={
                            isValid
                              ? 'primary_btn btn_block_mob'
                              : 'primary_btn btn_block_mob disabled_btn'
                          }
                        >
                          {t('activation.label.submit')}
                        </Button>
                      </div>
                      <div className="col-lg-auto col-md-auto col-sm12 col-12 mb-4">
                        <Button
                          dataTestId="cancelButton"
                          type="button"
                          onClick={cancelClick}
                          variant="secondary_btn btn_block_mob"
                        >
                          {t('activation.label.cancel')}
                        </Button>
                      </div>
                    </div>
                  </form>
                ) : (
                  <>
                    {/* success content */}
                    <div className="row justify-content-center pt-4">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                        <div className="mb-3 pb-1">
                          <CheckIconBig />
                        </div>
                        <div
                          className="literata_regular font-fourty fw-500 mb-3 pb-1"
                          data-testid="alertShowSuccessMessage"
                        >
                          {t('activation.messages.activationSuccess')}
                        </div>
                        <div className="black-color">
                          <div
                            className="mb-4"
                            data-testid="contactSupportText"
                          >
                            {t('forgotPassword.message.contactSupportText3')}{' '}
                            <Link to="tel:+17575527401">
                              {t('forgotPassword.message.contactSupportText4')}
                            </Link>
                            {t('dot')}
                          </div>
                        </div>
                        <div className="mb-4">
                          <Button
                            onClick={() => {
                              navigate(signIn);
                            }}
                            type="button"
                            dataTestId="redirectLoginPage"
                            variant="primary_btn"
                          >
                            {t('forgotPassword.label.returnLogin')}
                          </Button>
                        </div>
                      </div>
                    </div>
                    {/* success content */}
                  </>
                )}
              </div>
            )}
          </div>
        </div>

        <hr className="d-block d-lg-none d-sm-block d-md-block" />
      </div>
      <footer className="mt-auto">
        <FooterDisclaimer />
      </footer>
    </div>
  );
};

export default ForgotPassword;
