import { errorhandler ,  navigateToExternalUrl} from 'common';
import { useMySSOUrl } from '@sentara/sentara-api-hooks-core';
import { useState } from 'react';

export const useVirtualPopUpHooks = () => {  

    // Initialize navigation hook
    const { SSOUrlType } = useMySSOUrl();
    const [loading, setLoading] = useState<boolean>(false);

    const getMDliveSSOUrlFromAPI = async (type: string) => {
      setLoading(true);
      const res = await SSOUrlType(type); // Get SSO URL from API
      if (res?.data?.ssoUrl) {
        navigateToExternalUrl(res?.data?.ssoUrl); // Open SSO URL in a new tab
      } else if (res?.errorCode) {
        errorhandler(res?.errorCode);
      }
      setLoading(false);
    };

    const PhoneNumberLink = ({ phoneNumber }: { phoneNumber: string }) => {
      window.location.href = `tel:${phoneNumber}`;
    };

    return {
      getMDliveSSOUrlFromAPI,
      PhoneNumberLink,
      loading
    };
}