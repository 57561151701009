export const EmailRegex = /^[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  export const PasswordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/;

export const HasNumber = /\d/;
export const NumberFormat = /\D/g;
export const HasUpperCase = /[A-Z]/;
export const HasLowerCase = /[a-z]/;
export const HasSpecialCart = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
export const ProfileRegex = /\b(\w)/g;
export const HASALPHABET = /[^a-zA-Z\s]/g;
export const UnderScoreRegex = /[0-9_]/g;
export const AlphabetAndNumberRegex = /\W/g;
export const AlphaNumberHypenRegex = /[^a-zA-Z0-9-_]/g;
export const AlphaNumericUnderscore =  /^[a-zA-Z0-9_@.\- ]$/;
export const UnderScoreOnly = /^\d+$/;
export const DateFormatRegex =
  /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
export const RemoveDollarComma = /[!$]/g;
export const SpecialCharacter = /[^a-zA-Z '-]/g;
export const OnlyNumbers = /\D/g;
export const commonTerms = ['sentara', 'mychart', 'optima', 'myhealth'];

export const HasNumberGlobal = /\d+/g;
export const NumbersChar = '^[0-9]';
export const RemoveSpaceSlash = /[^a-zA-Z0-9]/g;
export const memberIdReg = /[^0-9a-zA-Z\*]/g;
export const userNameRegex = /^[A-Za-z]\w*$/;
export const RemoveNonDigitCharacters = /\D/g;
export const NoSpecialCharacter = /[^a-zA-Z0-9 ]/g;
export const onlyDateFormat = /[^0-9\/b]/;
export const patterns = [
  /^(\d{3})(\d*)$/,
  /^(\d{3})(\d{3})(\d*)$/,
  /^(\d{3})(\d{3})(\d{4})(\d*)$/,
];
export const urlRegex = /(https?:\/\/[^\s]+)/g;
