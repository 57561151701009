import { Paragraph } from '@sentaraui/optimahealth_web';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library, IconName } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';

library.add(fal);

interface CareTileProps {
  heading: string;
  subHeading?: string;
  onClick: () => void;
  dataTestId?: string;
  className?: string;
  fontAwesomeIconKey: string;
}

const CareTile: React.FC<CareTileProps> = ({
  heading,
  subHeading,
  onClick,
  dataTestId,
  className,
  fontAwesomeIconKey,
}) => {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      onClick();
    }
  };

  return (
    <div
      data-testid={dataTestId}
      className={`d-flex flex-column align-items-center p-3 ${className}`}
    >
      <div
        className="d-flex flex-column align-items-center cursor-pointer"
        onClick={onClick}
        role="button"
        tabIndex={0}
        onKeyDown={handleKeyDown}
      >
        <FontAwesomeIcon
          className="care-tile mb-3"
          icon={['fal', fontAwesomeIconKey as IconName]}
        />
        <span className="btn-links mb-1 text-center">{heading}</span>
      </div>
      {subHeading && (
        <Paragraph className="text-center">{subHeading}</Paragraph>
      )}
    </div>
  );
};

export default CareTile;
