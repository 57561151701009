import { useTranslation } from 'react-i18next';
import {
  Headings,
  ModalOverlay,
  SentaraLogo,
  MDLiveLogo,
  VirtualIcon,
  Loader
} from '@sentaraui/optimahealth_web';
import { VirtualVisitModalBox } from 'templates'; 
import { SSOKeys, errorhandler , PRIMARY_CARE_URL , navigateToExternalUrl, SuperScript} from 'common';
import { useState } from 'react';
import { useMySSOUrl } from '@sentara/sentara-api-hooks-core';

interface UrgentCarePopUpProps {
  closeModal: () => void;
  mdliveCopay?: string;
  sentaraCopay?: string;
}
function UrgentCarePopUp({
    closeModal,
    mdliveCopay,
    sentaraCopay
    
}: UrgentCarePopUpProps) {
    // Initialize translation hook
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    // Initialize navigation hook
    const { SSOUrlType } = useMySSOUrl();

    const sentaraDisclaimerList = [
      {superScript: <sup>{SuperScript.VIRTUAL_DISCLAIMER_ONE}</sup>, text: t('UrgentCare.sentaraContent.disclaimerNote1'), style:'font-size-14 mt-3'}!,
      {superScript: <sup>{SuperScript.VIRTUAL_DISCLAIMER_TWO}</sup>, text:t('UrgentCare.sentaraContent.disclaimerNote2') , style:'font-size-14 mt-2'}!,
      {text:t('NurseOnCall.sentaraContent.disclaimerNote2') , style:'font-size-14 mt-2'}!,
    ]

    const mdLiveDisclaimerList = [
        {superScript: <sup>{SuperScript.VIRTUAL_DISCLAIMER_TWO}</sup>, text:t('PrimaryCare.mdLiveContent.disclaimerNote1') , style:'font-size-14 mt-3'}!
      ]

    const getMDliveSSOUrlFromAPI = async (type: string) => {
      setLoading(true);
      const res = await SSOUrlType(type); // Get SSO URL from API
      if (res?.data?.ssoUrl) {
        navigateToExternalUrl(res?.data?.ssoUrl); // Open SSO URL in a new tab
      } else if (res?.errorCode) {
        errorhandler(res?.errorCode);
      }
      setLoading(false);
    };
  
    return (
      <>
        <ModalOverlay
          className="virtual-visit-selection"
          data-testid={'primaryCareSelectionId'}
          isOpen
          onClose={closeModal}
          overlayInfo="primary care selection"
        >
          <div>
          <Headings
            className="text-start"
            level={2}
            text={t('virtualVisitCare.selectProviderPopup.title')}/>
          <div className="d-flex flex-column flex-lg-row gap-4 mt-2 justify-content-center">
            <div className="curved-box">
              <VirtualVisitModalBox
                logoImage={<SentaraLogo/>}
                title={ t(
                  'UrgentCare.sentaraContent.title')}
                costValue={sentaraCopay}
                superTextOne={SuperScript.VIRTUAL_DISCLAIMER_ONE}
                superTextTwo={SuperScript.VIRTUAL_DISCLAIMER_TWO}
                costInfo={t('UrgentCare.sentaraContent.costInfo')}
                badgeImage={<VirtualIcon/>}
                content={t(
                  'UrgentCare.sentaraContent.serviceContent'
                )}
                buttonLabel={t(
                  'virtualVisitCare.selectProviderPopup.scheduleAppointment'
                )}
                buttonTestId='scheduleSentaraAppointment'
                buttonOnClick={() => {
                  navigateToExternalUrl(PRIMARY_CARE_URL); // Navigate to the URL
                }}
                minHeight={'250'}
                disclaimers={sentaraDisclaimerList}
              />
            </div>
            <div className=" curved-box">
              <VirtualVisitModalBox
                logoImage={<MDLiveLogo/>}
                title={t('virtualVisitCare.selectProviderPopup.mdliveTitle')}
                costInfo={t('PrimaryCare.sentaraContent.costInfo')}
                badgeImage={<VirtualIcon/>}
                content={t(
                  'UrgentCare.mdLiveContent.serviceContent'
                )}
                superTextTwo={SuperScript.VIRTUAL_DISCLAIMER_TWO}
                costValue={mdliveCopay}
                buttonLabel={t(
                  'virtualVisitCare.selectProviderPopup.scheduleAppointment'
                )}
                buttonTestId='scheduleMDliveAppointment'
                buttonOnClick={() => {
                  getMDliveSSOUrlFromAPI(SSOKeys.VirtualVisit);
                }}
                minHeight={'250'}
                disclaimers={mdLiveDisclaimerList}
              /> 
            </div>
          </div>
        </div>
        </ModalOverlay>
        {loading ? <Loader /> : null}
      </>
    );
  }
  
export default UrgentCarePopUp;