import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useMySSOUrl,
  usePreferenceDetails,
} from '@sentara/sentara-api-hooks-core';
import {
  getMaxLength,
  toPascalCase,
  benefitFocusSSOUrl,
  vlocityEnrollBenefits,
  AlphaNumberHypenRegex,
  apiResponseSafeCheck,
} from 'common';
import ApiWrapper from '../../common/ApiWrapper';
import { GlobalContext } from 'context';
import {
  Headings,
  Button,
  Label,
  InputField,
  Disclaimer,
  ErrorMessage,
  Loader,
  Paragraph,
} from '@sentaraui/optimahealth_web';
import { useForm } from 'react-hook-form';

const EnrollInBenefits = () => {
  const { preferenceDetails } = usePreferenceDetails();
  const { getFeature, preferenceDepedent, loginMID } =
    useContext(GlobalContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState<any>(false);
  const { SSOUrlType } = useMySSOUrl();
  const { data, callApi } = ApiWrapper(SSOUrlType);
  const {
    data: preferenceData,
    apiError,
    callApi: prefCallApi,
  } = ApiWrapper(preferenceDetails);
  // Calling a preferenceDetails API once get a response"
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, isValid },
  } = useForm<FormInputs>({}); /* useForm props extract */

  interface ProfileData {
    firstName?: string;
    lastName?: string;
    dob?: string;
  }
  interface FormInputs {
    accessCode: string;
  }
  const [profileData, setProfileData] = useState<ProfileData | null>({});

  /* SSO URL type API call */
  const onSubmit = async (data: FormInputs) => {
    const url = getFeature?.VlocityEnrollBenefits
      ? benefitFocusSSOUrl
      : vlocityEnrollBenefits;
    let urlFormat = url + '?accessCode=' + data.accessCode;
    callApi(urlFormat);
  };

  useEffect(() => {
    if (data) {
      const { ssoUrl } = data;
      window?.open(ssoUrl, '_blank');
    }
  }, [data]);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const data = {
        memberId: loginMID || '',
      };
      prefCallApi(data, '', getFeature?.AccountSettings);
    })();
  }, []);

  useEffect(() => {
    if (preferenceData) {
      setProfileData(preferenceData);
    }
    if (preferenceData || apiError) {
      setLoading(false);
    }
  }, [preferenceData, apiError]);

  useEffect(() => {
    if (apiResponseSafeCheck(preferenceDepedent)) {
      let parsedDetails = JSON.parse(preferenceDepedent);
      setProfileData(parsedDetails);
    }
  }, [preferenceDepedent]);

  return (
    <>
      {loading && <Loader />}
      <div className="enroll_in_benefits black-color">
        <div className="heading-one-semibold mb-4">
          <Headings
            level={1}
            text={t('enrollBenefits.label.title')}
            data-testid="enrollBenefitsHeader"
          />
        </div>
        <div className="">
          <Headings
            level={2}
            text={t('enrollBenefits.label.personalInfo')}
            data-testid="personalInforamtionBenefits"
            className="fw-600 redhat_semibold font-twentyfour mb-4"
          />
          <div className="row flex-nowrap pb-1">
            <Paragraph
              data-testid="personalInforamtionBenefits"
              className="col-auto enroll-label-wid fw-600 redhat_semibold mb-2"
            >
              {t('enrollBenefits.label.firstName')}
            </Paragraph>
            <div className="col-auto mb-2" data-testid="enrollFirstnameValue">
              <Paragraph className={'mb-0'}>
                {toPascalCase(profileData?.firstName ?? '')}
              </Paragraph>
            </div>
          </div>
          <div className="row pb-1">
            <Paragraph
              data-testid="EnrollLastname"
              className="col-auto enroll-label-wid fw-600 redhat_semibold mb-2"
            >
              {t('enrollBenefits.label.lastName')}
            </Paragraph>
            <div className="col-auto mb-2" data-testid="enrollLastnameValue">
              <Paragraph className={'mb-0'}>
                {toPascalCase(profileData?.lastName ?? '')}
              </Paragraph>
            </div>
          </div>
          <div className="row pb-3">
            <Paragraph
              data-testid="EnrollDob"
              className="col-auto enroll-label-wid fw-600 redhat_semibold mb-2"
            >
              {t('enrollBenefits.label.dob')}
            </Paragraph>
            <div className="col-auto mb-2" data-testid="enrollDobValue">
              <Paragraph className={'mb-0'}>
                {profileData?.dob?.split(' ')[0]}
              </Paragraph>
            </div>
          </div>
          <Headings
            level={2}
            text={t('enrollBenefits.label.empInfo')}
            data-testid="enrollEmployerInformation"
            className="fw-600 redhat_semibold font-twentyfour mb-4"
          />
          <form onSubmit={handleSubmit(onSubmit)} method="post">
            <div className="row mb-3">
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <Label
                  dataTestId="enterAccessCodeLabel"
                  htmlFor="regMemId"
                  children={t('enrollBenefits.label.enterAccessCode')}
                  className="input-label"
                />
                <div className="pos-rel">
                  <InputField
                    id="regMemId"
                    dataTestId="enterAccessCodeInput"
                    type="text"
                    maxLength={getMaxLength.employeeAccessCode}
                    // Remove the duplicate 'onChange' prop
                    placeholder={t('enrollBenefits.label.placeholderText')}
                    {...register('accessCode', {
                      required: true,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        setValue(
                          'accessCode',
                          e.target.value.replace(AlphaNumberHypenRegex, '')
                        );
                      },
                    })}
                    className={
                      errors.accessCode
                        ? 'error-border form-input'
                        : 'form-input'
                    }
                  />
                  {errors.accessCode && (
                    <div className="mt-1">
                      <ErrorMessage
                        children={t('enrollBenefits.error.accessCode')}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="mb-4">
              <Disclaimer
                data-testid="disclaimerContent"
                disclaimerContent={t('enrollBenefits.message.instruction')}
              />
            </div>
            <div className="mb-4">
              <Button
                dataTestId={'submitButton'}
                variant={
                  isValid
                    ? 'primary_btn btn_block_mob'
                    : 'btn_block_mob disabled_btn'
                }
                type="submit"
              >
                {t('enrollBenefits.label.submit')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EnrollInBenefits;
