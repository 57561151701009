import { useEffect, useState, useContext} from 'react';
import {
  sessionIdleTime,
  UserDetails,
  getDecryptedValue,
} from 'common';
import { GlobalContext } from 'context';
const { App } = require('@sentaraui/chatbot_web/dist');

interface ChatBotProps {
  apiUrl: string;
  memberId: string;
  userId: string;
  token: string;
  subscriptionKey: string;
  phone: string;
  chatBotStatus: boolean;
  sessionIdle: number;
  firstName: string;
  lastName: string;
  email: string;
  satmetrixKey: string;
  satmetrixSecret: string;
  xAuthToken:  string;
    xSentaraTokenId:  string;
}
const Chatbot = ({ setShowChat, refreshBot, reset, onMinimize, minimizeChat }: any) => {
  const [chatBotConfig, setChatBotConfig] = useState<ChatBotProps>({} as ChatBotProps);
  const {
    loginMemberId,
    serviceNumber,
    getAuthToken
  } = useContext(GlobalContext);
  useEffect(() => {
    (async () => {
      let localServiceNumber = null;
      const authToken = getAuthToken;
      const PersonalInfo = await getDecryptedValue(UserDetails.guest);
      const userData = JSON.parse(PersonalInfo || '');
      const userId = await getDecryptedValue(UserDetails.usedId);

      if(serviceNumber){
        localServiceNumber = JSON.parse(serviceNumber || '');
        localServiceNumber =localServiceNumber?.data;
      }
      
      const xAuthToken = userData?.xAuthToken || '';
      const xSentaraTokenID = userData?.xSentaraTokenID || '';
   
      
      let chatBotConfig =  {
        apiUrl: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CHAT_BOT_URL}`,
        memberId: btoa(loginMemberId),
        userId: btoa(userId || ''),
        token: authToken|| '',
        subscriptionKey: process.env.REACT_APP_SUBSCRIPTION_KEY || '',
        phone: localServiceNumber,
        chatBotStatus: false,
        sessionIdle: sessionIdleTime,
        firstName: userData?.user?.firstName,
        lastName: userData?.user?.lastName,
        email: userData?.user?.emailId,
        satmetrixKey: process.env.REACT_APP_SATMETRIX_KEY || '',
        satmetrixSecret: process.env.REACT_APP_SATMETRIX_SECRET || '',
        xAuthToken: xAuthToken,
        xSentaraTokenId: xSentaraTokenID
      };
      setChatBotConfig(chatBotConfig)
    })();
  }, []);

  return (
    <>
      {chatBotConfig && Object.keys(chatBotConfig).length > 0 &&
        <div className={`chatbot_frame`} data-testid={'chatbot-frame'}>
          <App
            botConfig={chatBotConfig}
            onClose={setShowChat}
            refreshBot={refreshBot}
            onMinimize={onMinimize}
            reset={reset}
          />
        </div>
      }
    </>
  );
};

export default Chatbot;
