import { useTranslation } from 'react-i18next';
import {
  Headings,
  ModalOverlay,
  SentaraLogo,
  VirtualIcon
} from '@sentaraui/optimahealth_web';
import { VirtualVisitModalBox } from 'templates'; 
import { LAB_SERVICES_URL , navigateToExternalUrl, SuperScript} from 'common';

interface LabServicePopUpProps {
  closeModal: () => void;
}
function LabServicePopUp({
    closeModal,
}: LabServicePopUpProps) {
    // Initialize translation hook
    const { t } = useTranslation();

    const sentaraDisclaimerList = [
      {superScript: <sup>{SuperScript.VIRTUAL_DISCLAIMER_ONE}</sup>,text:t('LabServices.sentaraContent.disclaimerNote1') , style:'font-size-14 mt-3'}!,
      {superScript: <sup>{SuperScript.VIRTUAL_DISCLAIMER_TWO}</sup>, text:t('LabServices.sentaraContent.disclaimerNote2') , style:'font-size-14 mt-2'}!,
      {superScript: <sup>{SuperScript.VIRTUAL_DISCLAIMER_THREE}</sup>, text:t('LabServices.sentaraContent.disclaimerNote3') , style:'font-size-14 mt-2'}!,
    ]


    return (
      <>
        <ModalOverlay
          className="virtual-visit-box"
          data-testid={'virtualVisitSelectionId'}
          isOpen
          onClose={closeModal}
          overlayInfo="virtual visit selection"
        >
          <Headings
            className="text-start"
            level={2}
            text={t('virtualVisitCare.selectProviderPopup.title')}/>
         <div className="curved-box-single-box gap-4 mt-3 w-100">
              <VirtualVisitModalBox
                logoImage={<SentaraLogo/>}
                title={t(
                  'LabServices.sentaraContent.title'
                )}
                costInfo={t('LabServices.sentaraContent.costInfo')}
                badgeImage={<VirtualIcon/>}
                content={t(
                  'LabServices.sentaraContent.labServiceContent'
                )}
                superTextOne={SuperScript.VIRTUAL_DISCLAIMER_ONE}
                superTextTwo={SuperScript.VIRTUAL_DISCLAIMER_TWO}
                superTextThree={SuperScript.VIRTUAL_DISCLAIMER_THREE}
                costValue={SuperScript.VIRTUAL_DISCLAIMER_ONE}
                buttonLabel={t(
                  'virtualVisitCare.selectProviderPopup.scheduleAppointment'
                )}
                buttonTestId='scheduleSentarappointment'
                buttonOnClick={() => {
                  navigateToExternalUrl(LAB_SERVICES_URL); // Navigate to the URL
                }}
                minHeight={'200'}
                disclaimers={sentaraDisclaimerList}
              />
         </div>
        </ModalOverlay>
      </>
    );
  }
  
export default LabServicePopUp;