import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  ChangePassword,
  ConfirmPassword,
  PasswordStrengthMeter,
} from 'templates';
import {
  setStrengthBarColor,
  signIn,
  sessionTimedOut,
  userLockedOut,
} from 'common';
import { PasswordInfoOverlay } from 'pages';
import {
  useForgetPassword,
  useForgetPasswordToken,
} from '@sentara/sentara-api-hooks-core';
import {
  Button,
  CheckIconBig,
  Headings,
  ModalOverlay,
  HelpIcon,
  GlobalError,
} from '@sentaraui/optimahealth_web';

import { errorCodeToStateUpdate, PASSWORD_CRITERIA } from './PasswordConstant';
import { useForm, FormProvider } from 'react-hook-form';
import { GlobalContext } from 'context';

interface FormInputs {
  password: string;
  confirmPassword: string;
}
// Define the interface for the props passed to PasswordChange component

function ResetPassword({ params, authId = '' }: any) {
  const { t } = useTranslation();
  const methods = useForm<FormInputs>({});
  const {
    handleSubmit,
    formState: { isValid },
  } = methods;
  const [isPasswordModal, setPasswordModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const [resetData, setResetData] = useState<any>({});
  const { forgetPassword } = useForgetPassword();
  const [pwdStrength, setPwdStrength] = useState(0);
  const [value, setValue] = useState<any>('');
  const [retriveSuccess, setRetriveSuccess] = useState(false);
  const [noContent, setNoContent] = useState(false);
  const [unauthError, setUnauthError] = useState(false);
  const [tokenExpiry, setTokenExpiry] = useState(false);
  const [passCriteria, setPassCriteria] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [userLockoutError, setUserLockoutError] = useState(false);
  const { forgetPasswordToken } = useForgetPasswordToken();
  const [authIdValue, setAuthIdValue] = useState<string>('');
  const { getFeature } = useContext(GlobalContext);

  useEffect(() => {
    if (typeof getFeature?.isModernizedAPI === 'boolean') {
      (async () => {
        if (params !== null) {
          let tokenId = { encryptedValue: params };
          const response: any = await forgetPasswordToken(tokenId);
          if (response?.errorCode) {
            const unAuthorizedError = [400, 401, 404, 410, 462, 500];
            const unAuthError = unAuthorizedError.includes(
              Number(response?.errorCode)
            );
            if (unAuthError) {
              setTokenExpiry(true);
            }
          } else {
            setAuthIdValue(response?.data?.authId);
            setTokenExpiry(false);
          }
        }
      })();
    }
  }, [params, getFeature?.isModernizedAPI]);

  useEffect(() => {
    setStrengthBarColor(value, setPwdStrength);
  }, [value]);

  useEffect(() => {
    return setResetData({
      user: {
        NewPassword: value,
        token: params,
        authId: authIdValue,
      },
      channel: 'Web',
      lang: 'en',
    });
  }, [value]);

  /* oncancel click navigate to sign in */
  const cancelClick = () => {
    navigate(signIn);
  };

  // Render global error
  const renderGlobalError = (condition: boolean, messageKey: string) => {
    return condition ? (
      <GlobalError variant="mb-3" dataTestId={'confirmPasswordInputError'}>
        {t(messageKey)}
      </GlobalError>
    ) : null;
  };

  // Handle form submission
  const formSubmitHandler = async (data: FormInputs) => {
    if (pwdStrength === 4) {
      await handleForgetPassword();
    }
  };

  function openModalPassword() {
    setPasswordModalOpen(true);
  }
  function closeModalPassword() {
    setPasswordModalOpen(false);
  }
  const handleForgetPassword = async () => {
    let response = await forgetPassword(resetData);
    const errorToStateUpdate = errorCodeToStateUpdate(
      setNoContent,
      setUnauthError,
      setPassCriteria,
      setTokenExpiry,
      setServerError
    );
    if (response?.errorCode) {
      if (
        response?.errorDetails &&
        response?.errorDetails?.message === sessionTimedOut
      ) {
        setTokenExpiry(true);
      } else if (
        response?.errorDetails &&
        response?.errorDetails === userLockedOut
      ) {
        setUserLockoutError(true); // Set user locked out error
        setPassCriteria(false); // Unset password criteria error
      } else if (response.errorCode in errorToStateUpdate) {
        errorToStateUpdate[
          response.errorCode as keyof typeof errorToStateUpdate
        ](true);
      } else {
        setServerError(true);
      }
    } else {
      setRetriveSuccess(true);
    }
  };

  // Handle key down event
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Space') {
      e.preventDefault();
    }
  };

  const inputStrengthClass =
    pwdStrength in PASSWORD_CRITERIA
      ? `strength-bar-${PASSWORD_CRITERIA[pwdStrength]}`
      : '';

  return (
    <>
      <div className="col-lg-5 col-md-9 col-sm-12 col-12 mb-3">
        <div
          className={`pb-3 ${
            retriveSuccess === true || tokenExpiry === true ? 'text-center' : ''
          }`}
        >
          <Headings
            level={1}
            text={t('resetPassword.label.header')}
            dataTestId="userNameForgot"
          />
        </div>
        {!retriveSuccess && !tokenExpiry && params !== null ? (
          <>
            <div className="mb-4">
              {t('resetPassword.message.resetPasswordInfo')}
            </div>
            {renderGlobalError(
              noContent,
              'resetPassword.message.passwordServiceErr1'
            )}
            {renderGlobalError(
              unauthError,
              'resetPassword.message.passwordServiceErr2'
            )}
            {renderGlobalError(
              passCriteria,
              'resetPassword.message.passwordServiceErr3'
            )}
            {renderGlobalError(
              serverError,
              'activation.errors.activationError4'
            )}
            {renderGlobalError(
              userLockoutError,
              'activation.errors.accountLockoutMessage'
            )}
            <FormProvider {...methods}>
              <form
                data-testid="passwordInputLabelContainer"
                onSubmit={handleSubmit(formSubmitHandler)}
              >
                <div className="pb-4">
                  {/* Render ChangePassword component */}
                  <div className="d-flex align-items-center">
                    <div className="pos-rel flex-1">
                      <ChangePassword
                        onKeyDown={onKeyDown}
                        setValue={setValue}
                        setNoContent={setNoContent}
                        setUnauthError={setUnauthError}
                        setPassCriteria={setPassCriteria}
                        setServerError={setServerError}
                        showPasswordPlaceholder={`${t(
                          'activation.label.passwordPlaceholder'
                        )}`}
                      />
                    </div>
                    <div className="ms-2 mb-2">
                      <button
                        className="border-0 bg-transparent"
                        data-testid="passwordInputHelpIcon"
                        onClick={(e) => {
                          e.preventDefault();
                          openModalPassword();
                        }}
                      >
                        <HelpIcon />
                      </button>
                    </div>
                  </div>
                </div>
                {/* Render PasswordStrengthMeter component */}
                <div className="pb-2">
                  <PasswordStrengthMeter
                    inputStrengthClass={inputStrengthClass}
                  />
                </div>

                {/* Render ConfirmPassword component */}
                <div className="pb-2">
                  <ConfirmPassword
                    onKeyDown={onKeyDown}
                    showPasswordPlaceholder={' '}
                  />
                </div>
                <div className="d-flex flex-column flex-lg-row flex-md-row align-items-center justify-content-center gap-4 mb-4">
                  <Button
                    dataTestId="submitButton"
                    variant={
                      isValid
                        ? 'primary_btn btn_block_mob  w-100 mw-auto'
                        : 'primary_btn btn_block_mob disabled_btn  w-100 mw-auto'
                    }
                    type="submit"
                  >
                    {t('activation.label.continue')}
                  </Button>
                  <Button
                    dataTestId="cancelButton"
                    type="button"
                    onClick={cancelClick}
                    variant="secondary_btn btn_block_mob w-100 mw-auto"
                  >
                    {t('activation.label.cancel')}
                  </Button>
                </div>
              </form>
            </FormProvider>
          </>
        ) : (
          <>
            {/* success content */}
            <div className="row justify-content-center pt-4">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                {retriveSuccess && !tokenExpiry ? (
                  <>
                    <div className="mb-3 pb-1">
                      <CheckIconBig />
                    </div>
                    <div
                      className="literata_regular font-fourty fw-500 mb-3 pb-1"
                      data-testid="alertShowSuccessMessage"
                    >
                      {t('activation.messages.activationSuccess')}
                    </div>
                    <div className="black-color">
                      <div className="mb-3" data-testid="resetLinkText">
                        {t('resetPassword.message.passwordResetSuccess')}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="black-color">
                    <div className="mb-3" data-testid="resetLinkText">
                      {t('forgotPassword.message.showTokenError')}
                    </div>
                  </div>
                )}

                <div className="mb-4">
                  <Button
                    onClick={() => {
                      navigate(signIn);
                    }}
                    type="button"
                    dataTestId="redirectLoginPage"
                    variant="primary_btn"
                  >
                    {t('forgotPassword.label.returnLogin')}
                  </Button>
                </div>
              </div>
            </div>
            {/* success content */}
          </>
        )}
      </div>
      <ModalOverlay
        isOpen={isPasswordModal}
        onClose={closeModalPassword}
        data-userid="passwordInputModal"
        contentLabel="Password Information Overlay"
      >
        <div data-testid="passwordInfoModal">
          <PasswordInfoOverlay />
        </div>
      </ModalOverlay>
    </>
  );
}
export default ResetPassword;
