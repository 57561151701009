import { useTranslation } from 'react-i18next';
import {
  ModalOverlay,
  PhoneCallIcon,
  Loader,
  SentaraLogo
} from '@sentaraui/optimahealth_web';
import { VirtualVisitModalBox } from 'templates'; 
import { useState } from 'react';
import { SERVICE_PHONE_NUMBER } from 'common';
import { useVirtualPopUpHooks } from './hooks/VirtualPopUpHooks';

interface NurseOnCallPopUpProps {
  closeModal: () => void;
}
function NurseOnCallPopUp({
    closeModal
}: NurseOnCallPopUpProps) {
    // Initialize translation hook
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);

    const sentaraDisclaimerList = [
      {text:t('NurseOnCall.sentaraContent.disclaimerNote1') , style:'font-size-14 mt-3'}!,
      {text:t('NurseOnCall.sentaraContent.disclaimerNote2') , style:'font-size-14 mt-2'}!,
    ]

    const nurseOnCallPopUpHooks = useVirtualPopUpHooks();


    return (
      <>
        <ModalOverlay
          className="virtual-visit-box"
          data-testid={'virtualVisitSelectionId'}
          isOpen
          onClose={closeModal}
          overlayInfo="virtual visit selection"
        >
            <div className="curved-box-single-box gap-4 w-100">
              <VirtualVisitModalBox
                logoImage={<SentaraLogo/>}
                title={t(
                  'NurseOnCall.sentaraContent.title'
                )}
                subtitle={t('NurseOnCall.sentaraContent.nurseOnCall')!}
                badgeImage={<PhoneCallIcon/>}
                content={t(
                  'NurseOnCall.sentaraContent.nurseOnCallContent'
                )}
                buttonLabel={t(
                  'NurseOnCall.sentaraContent.buttonText'
                )}
                 buttonTestId='sentaraCallButton'
                buttonOnClick={() => {
                  nurseOnCallPopUpHooks.PhoneNumberLink({ phoneNumber: SERVICE_PHONE_NUMBER });
                }}
                displayBlock={true}
                minHeight={'200'}
                disclaimers={sentaraDisclaimerList}
              />
            </div>
        </ModalOverlay>
        {loading ? <Loader /> : null}
      </>
    );
  }
  
export default NurseOnCallPopUp;