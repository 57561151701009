import { useAuthorizationDetails } from '@sentara/sentara-api-hooks-core';
import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiResponseSafeCheck, getCache } from 'common';
import ApiWrapper from '../../common/ApiWrapper';
import AuthorizationDetailsTable from './AuthorizationDetailsTable';
import {
  PrintIcon,
  ContactInfo,
  Headings,
  Button,
} from '@sentaraui/optimahealth_web';
import { GlobalContext } from 'context';

const AuthorizationDetails = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [authDetails, setAuthDetails] = useState<any>();
  const [phoneNumber, setPhoneNumber] = useState<any>({});
  const { authorizationDetailsType } = useAuthorizationDetails();
  const { data, callApi } = ApiWrapper(authorizationDetailsType);
  const { serviceNumber } = useContext(GlobalContext);

  const apiCall = () => {
    const params = {
      AuthID: location.state.authId,
      memberID: location.state.memberId,
    };

    callApi(params, getCache());
  };

  useEffect(() => {
    apiCall();
  }, []);

  useEffect(() => {
    if (data) {
      setAuthDetails(data);
      if (serviceNumber) {
        setPhoneNumber(JSON.parse(serviceNumber ?? '{}'));
      }
    }
  }, [data]);

  const Print = (e: any) => {
    e.preventDefault();
    window.print();
  };
  return (
    <div>
      <div className="d-flex not-print align-items-center pt-2 mb-4 pb-2">
        <div className="flex-1">
          <Button
            data-testid="back-to-Authorization"
            className="btn-links link-text arrow-back"
            onClick={(e: { preventDefault: () => void }) => {
              e.preventDefault();
              navigate('/home/authorizations');
            }}
          >
            {t('authorizationDetails.label.backToAuthorizationList')}
          </Button>
        </div>
        <div className="d-flex align-items-center">
          <span className="pe-2" data-testid={'print-icon'}>
            <PrintIcon />
          </span>
          <Button
            className="link-text btn-links ms-1 pt-1"
            onClick={Print}
            dataTestId={'print-link'}
          >
            {t('authorization.label.print')}
          </Button>
        </div>
      </div>
      <div className="print heading-one-semibold mb-4">
        <Headings
          level={1}
          text={t('authorizationDetails.label.title')}
          dataTestId="authorizationDetailsHeader"
        />
      </div>
      <AuthorizationDetailsTable authDetails={authDetails} />
      {apiResponseSafeCheck(phoneNumber?.data) ? (
        <div className="mb-4 pb2" data-testid="contact_services">
          {t('authorizationDetails.message.questions')}{' '}
          <ContactInfo
            phoneData={{
              phone1800: phoneNumber?.data?.benefitPhone1800,
              phoneLocal: phoneNumber?.data?.benefitPhoneLocal,
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default AuthorizationDetails;
