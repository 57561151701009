import React, { useEffect, useState, useContext } from 'react';
import {
  HeaderLogo,
  ContactUsLink,
  SearchLink,
  HamburgerMenuLink,
  FooterDisclaimer,
  FooterNav,
} from 'organisms';
import { useNavigate } from 'react-router-dom';
import { getFeatureAttribute, MyFeatureAttributeResponse } from 'pages';
import {
  getMaxLength,
  ProfileUpdate,
  UserDetails,
  HasNumberGlobal,
  setEncryptedValue,
  useAuth,
  Navigate,
  getLocalStorageBoolean,
  claimsSummary,
  deleteEncryptedValue,
  apiResponseSafeCheck,
  AlphaNumericUnderscore,
  getDecryptedValue,
  LoginAuthToken,
  forgotUsername,
  resetPassword,
  setSessionStorageBoolean,
  SHOW_PREFERENCE_POPUP,
  userLockedOut,
  handleKeyDownUsername
} from 'common';
import LoginBanner from '../../assets/images/login_banner_img_4x.webp';
import LoginBannerAvMed from '../../assets/images/Hero Image_Desktop_Laptop.svg';
import {
  useUserLogin,
  useFeaturePermission,
  useMyFeatureAttribute,
} from '@sentara/sentara-api-hooks-core';
import { useForm } from 'react-hook-form';
import {
  Button,
  Label,
  ErrorMessage,
  Headings,
  InputField,
  ModalOverlayWithoutClose,
  GlobalError,
  EyeIconClose,
  EyeIconOpen,
  Loader,
  Link,
  Paragraph
} from '@sentaraui/optimahealth_web';
import { GlobalContext, isAvMed } from 'context';
import { useTranslation } from 'react-i18next';

// Define the main functional component for the sign-in form
const SignInForm = () => {
  // State variables
  const {
    setLoginMemberId,
    setLoginMID,
    setLoginProxyId,
    setUserEncodedData,
    setAge,
    loginMID,
    setSSOLink,
    setProxyUniqueId,
    proxyUniqueId,
    setAuthToken,
    getAuthToken
  } = useContext(GlobalContext);
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [guest, setGuest] = useState<boolean>(false);
  const [errorStatusMessage, setErrorStatusMessage] = useState<boolean>(false);
  const [internalServerError, setInternalServerError] =
    useState<boolean>(false);
  const [passwordOpen, setPasswordOpen] = useState<boolean>(false);
  const inActiveSignOut: boolean = getLocalStorageBoolean(
    UserDetails?.inActiveSignOut
  );
  const [isOpen, setIsOpen] = useState<boolean>(inActiveSignOut);

  // Fetch user state and login function from API hooks
  const { userLogin } = useUserLogin();
  const { getFeaturePermission } = useFeaturePermission();
  const { MyFeatureAttributeType } = useMyFeatureAttribute();
  const [loading, setLoading] = useState(false);

  // Show or hide account locked popup
  const [showPopup, setShowPopup] = useState<boolean>(false);

  // Form validation using React Hook Form
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      memberId: '',
      password: '',
    },
  });

  // Watch for changes in username and password inputs
  const watchUsername = watch('memberId');
  const watchPassword = watch('password');

  const ImageLogo = isAvMed() ? LoginBannerAvMed : LoginBanner

  //Clearing all localstorage when its rendering at inital time
  useEffect(() => {
    localStorage.clear();
    setSessionStorageBoolean(SHOW_PREFERENCE_POPUP, false);
    deleteEncryptedValue();
  }, []);

  let search = window.location.search;

  // to get the EOB params from the URL
  const params = new URLSearchParams(search);
  const redirectValue: string = params.get('redirect') || '';
  
  // Reset password error message on username or password change
  useEffect(() => {
    document.title = t('pageTitle.label.login');
    if (redirectValue && redirectValue.includes('/?proxy=')) {
      const proxyId = redirectValue.split('/?proxy=');
      setProxyUniqueId(proxyId[1]);
    }
  }, [watchUsername, watchPassword, redirectValue]);
  const navigate = useNavigate();

  // Authentication context
  const auth: any = useAuth();

  // Handle error codes and display error messages

  const handleError = (errorCode: string | undefined): void => {
    localStorage.clear();
    deleteEncryptedValue();
    const regex = HasNumberGlobal;
    const getStatusCode: string | null =
    errorCode?.match(regex)?.toString() ?? errorCode ?? '';
    const errorStatusCode: string[] = ['441', '400', '442', '445', '420'];
    const errorStatusCode2: string[] = ['401', '440'];
    const internalServerError: string[] = ['500', 'Network Error'];
    const checkError: boolean = errorStatusCode.includes(getStatusCode);
    const checkError2: boolean = errorStatusCode2.includes(getStatusCode);
    const checkServerError: boolean =
      internalServerError.includes(getStatusCode);

    if (checkError) {
      setErrorMessage(true);
    } else if (checkError2) {
      setErrorStatusMessage(true);
      return;
    } else if (checkServerError) {
      setInternalServerError(true);
      return;
    } else {
      setErrorMessage(false);
      setErrorStatusMessage(false);
      setInternalServerError(false);
    }
  };

  const setProfileData = (
    response: {
      data: {
        rules: { profileUpdate: string };
        authToken: string;
        user: { firstName: string; lastName: string };
        bearerToken: string;
      };
    },
    user: {}
  ) => {
    if (response?.data?.rules?.profileUpdate) {
      localStorage.setItem(ProfileUpdate, response?.data?.rules?.profileUpdate);
    }
    if (response?.data?.authToken) {
      handleAuthToken(response, setEncryptedValue, setAuthToken)
    }
    auth.login(user ? response?.data?.authToken : null);
    //setting domain cookie
    const optimaPersonCookie =
      'FirstName=' +
      response?.data?.user?.firstName +
      '&' +
      'LastName=' +
      response?.data?.user?.lastName +
      ';' +
      'domain=.optimahealth.com;path=/;';
    document.cookie = UserDetails.optimaPersonCookie + '=' + optimaPersonCookie;
    document.cookie =
      UserDetails.optimaRoleCookie +
      '=' +
      'Role=members;domain=.optimahealth.com;path=/';
  };

  // // Handle form submission
  const onSubmit = async (data: { memberId: string; password?: string }) => {
    setInternalServerError(false);
    const user: any = {
      userId: data.memberId ?? '',
      password: data.password ?? '',
      channel: 'Web',
      lang: 'en',
      identification: {
        deviceId: 'string',
      },
    };

    setLoading(true);
    const response: any = await userLogin(user);
    setEncryptedValue(UserDetails.usedId, data?.memberId);
    setEncryptedValue(UserDetails.guest, JSON.stringify(response?.data));
    setGuest(response?.data?.isGuest);

    if (response?.error) {
      handleError(response?.error); // Assuming this function handles displaying the error to the user
      setLoading(false);
    }

    if (response?.error === t('userprofile.message.error_419') 
      || response?.errorDetails === userLockedOut) {
      setShowPopup(true);
    }
    if (!response?.data?.isGuest) {
      setProfileData(response, user);
    } else {
      auth.login(response?.data?.authToken ?? null);
      if (response?.data?.authToken) {
        handleAuthToken(response, setEncryptedValue, setAuthToken)
      }
    }
  };

    const handleAuthToken = (
    response: LoginAuthToken,
    setEncryptedValue: (key: string, value: string) => void,
    setAuthToken: (token: string) => void,
  ) => {
    if(response?.data?.authToken ){
      setEncryptedValue(UserDetails.authToken, response.data.authToken);
      setEncryptedValue(UserDetails.bearerToken, response.data.bearerToken ?? '');
      setAuthToken(response.data.authToken);
    }
  }

  //featurePermission api to navigate to the dashboard screen
  useEffect(() => {
    (async () => {
      if(getAuthToken){
        const authToken = await getDecryptedValue(UserDetails.authToken);
          if (apiResponseSafeCheck(authToken)) {
            const response = await getFeaturePermission();
            if (response?.error) {
              setLoading(false);
              handleError(response?.error); // This function handles displaying the error message to the user
            } else {
              setLoginMID(response?.data?.mId ?? '');
              setLoginMemberId(atob(response?.data?.memberId?? ''));
              setLoginProxyId(response?.data?.proxyId ?? '');
              // If the user is not a guest, call the getLaunchDarkly function.
              if(!guest){
                getLaunchDarkly();
              } else {
                navigate(Navigate.dashboard);
                setLoading(false);
              }
            }
          }
      }

    })();
   }, [getAuthToken]);

  //getLaunchDarkly api to navigate to the dashboard screen
  const getLaunchDarkly = async () => {
    const response = await MyFeatureAttributeType();
    if (response?.errorCode !== undefined) {
      setInternalServerError(true);
    } 
    else {
        setUserEncodedData(getFeatureAttribute(response?.data as MyFeatureAttributeResponse));
        setAge(response?.data?.age);
        // to navigate to the medical claims screen if it is EOB link
        if(redirectValue && !proxyUniqueId){
          if(redirectValue.includes(claimsSummary)){
            navigate(Navigate.medicalClaimsURL)
          }else if(redirectValue.includes('ssolanding')){
              setSSOLink(redirectValue);
              navigate(Navigate.dashboard);
          } else {
            navigate(redirectValue);
          }
        } else{
            navigate(Navigate.dashboard);
        }
    }
    setLoading(false);
  };

  // Reset the form and error messages
  const resetForm = () => {
    reset({ memberId: '', password: '' });
    setErrorMessage(false);
    setShowPopup(false);
  };
  // Close the popup and clear localStorage
  function popupClose() {
    localStorage.clear();
    deleteEncryptedValue();
    setIsOpen(false);
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  // Render the sign-in form and related components
  return (
    <>
      {loading && <Loader />}
      <div className="d-flex flex-column h-100">
        {/* Skip to Main Content link */}
        <Link to="#main-content" className="skip-link">
          {t('accessibility.message.skipToMainContent')}
        </Link>
        {/* Header section */}
        <div>
          <header>
            {/* Container for the header */}
            <div className="container-fluid">
              {/* Navigation bar */}
              <div>
                <nav className="d-flex align-items-center justify-content-between pt-3 mb-3">
                  <div>
                    {/* Component for the header logo */}
                    <HeaderLogo />
                  </div>
                  <div>
                    <div className="d-flex flex-no-wrap align-items-center">
                      {/* Contact us link, visible on extra-large and large screens */}
                      <div className="d-none d-xl-block d-lg-block d-md-none ms-4">
                        <ContactUsLink memberID={loginMID} showIcon={false} />
                      </div>
                      {/* Search link, visible on large, medium, and small screens */}
                      <div className="d-lg-none d-md-block d-sm-block">
                        <SearchLink />
                      </div>
                      {/* Hamburger menu link, visible on large, medium, and small screens */}
                      <div className="d-lg-none d-md-block d-sm-block">
                        <HamburgerMenuLink showLogout={false} />
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
            {/* Horizontal line separating the header from the rest of the content */}
            <hr className="border-w-2 mb-lg-0" />
          </header>

          {/* Main content container */}
          <div id="main-content" className={`container-fluid ${isAvMed() ? "dashboard-bg-img" : ""}`} role="main">
            <div className="row align-items-center justify-content-center">
              {/* Form container */}
              <div className="col-lg-5 col-md-6 col-sm-12 col-12">
                  {/* Global errors */}
                  {(errorMessage ||
                    errorStatusMessage ||
                    internalServerError) && (
                      <GlobalError variant="mb-3" dataTestId="serviceError">
                        {errorMessage || errorStatusMessage
                          ? t('serviceError')
                          : t('loginServerError')}
                      </GlobalError>
                  )}
                  {/* Heading */}
                  <div className="text-md-center text-lg-start pb-5">
                    <Headings
                      level={1}
                      text={t('account.label.login')}
                      dataTestId="signInHeaderText"
                    />
                  </div>
                  {/* User login form */}

                  <form onSubmit={handleSubmit(onSubmit)} onKeyPress={handleKeyPress}>
                    <div className="mb-3 singin-input pos-rel">
                      <div aria-hidden="true" data-focusable="false">
                        <Label
                          className={'pb-1 d-none'}
                          htmlFor="password"
                          children={t('activation.label.userNameSmall')}
                        />
                      </div>
                      <InputField
                        data-testid="userNameInput"
                        {...register('memberId', { required: true })}
                        onKeyDown={handleKeyDownUsername}
                        id="memberId"
                        type="text"
                        placeholder={t('activation.label.userNameSmall') ?? ''}
                        maxLength={getMaxLength.userName}
                        onFocus={() => {
                          setInternalServerError(false);
                          setErrorStatusMessage(false);
                          setErrorMessage(false);
                        }}
                        className={
                          errors.memberId
                            ? 'error-border form-input'
                            : 'form-input'
                        }
                        aria-describedby="userid_error"
                      />
                      {errors.memberId && (
                        <div className="mt-1">
                          <ErrorMessage
                            children={t('activation.errors.usernameRequired')}
                          />
                        </div>
                      )}
                      <ModalOverlayWithoutClose
                        isOpen={isOpen}
                        overlayInfo="Modal information"
                      >
                        <Paragraph
                          className="d-none d-lg-block mb-4 pt-1"
                          data-testid={"sessionModalDescription"}
                        >{t('session.label.logoutdesc')}
                        </Paragraph>
                        <div className="text-center">
                          <Button
                            dataTestId={'modalOkButton'}
                            variant={'primary_btn btn_block_mob'}
                            type="submit"
                            onClick={popupClose}
                          >{t('session.label.buttonOk')}
                          </Button>
                        </div>
                      </ModalOverlayWithoutClose>
                      {/* Popup/modal for displaying additional information */}
                      {showPopup && (
                        <div className="modal_popup_login">
                          <ModalOverlayWithoutClose
                            isOpen
                            onClose={() => {}}
                            data-testid="maximumAttemptInfo"
                            overlayInfo={t(
                              'activation.label.maximumAttemptInfo'
                            )}
                          >
                            {t('activation.errors.accountLockedError')}
                            <div className="d-flex align-items-center justify-content-center mt-3">
                              <Button
                                dataTestId="resetButton"
                                className="primary_btn btn_block_mob"
                                onClick={() => resetForm()}
                              >
                                {t('appModel.label.ok')}
                              </Button>
                            </div>
                          </ModalOverlayWithoutClose>
                        </div>
                      )}
                    </div>
                    <div className="mb-3 singin-input pos-rel">
                      <div aria-hidden="true" data-focusable="false">
                        <Label
                          className={'pb-1 d-none'}
                          htmlFor="password"
                          children={t('activation.label.password')}
                        />
                      </div>
                      <InputField
                        data-testid="passwordInput"
                        {...register('password', { required: true })}
                        id="password"
                        type={passwordOpen ? 'text' : 'password'}
                        placeholder={t('activation.label.password') ?? ''}
                        maxLength={getMaxLength.password}
                        onFocus={() => {
                          setInternalServerError(false);
                          setErrorStatusMessage(false);
                          setErrorMessage(false);
                        }}
                        className={
                          errors.password
                            ? 'error-border form-input'
                            : 'form-input'
                        }
                        showIcon={true}
                        aria-describedby="password_error"
                      />
                      {errors.password && (
                        <div className="mt-1">
                          <ErrorMessage
                            children={t('activation.errors.passwordRequired')}
                          />
                        </div>
                      )}
                      {/* Button to toggle password visibility */}
                      <Button
                        dataTestId="eyeIconButton"
                        className="eye_icon"
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                          e.preventDefault();
                          setPasswordOpen(!passwordOpen);
                        }}
                      >
                        <span aria-live="polite">
                          {!passwordOpen ? (
                            <span
                              aria-describedby="password"
                              aria-label={
                                t('account.message.eyeIconShow') ?? ''
                              }
                            >
                              <EyeIconClose />
                            </span>
                          ) : (
                            <span
                              aria-describedby="password"
                              aria-label={
                                t('account.message.eyeIconHide') ?? ''
                              }
                            >
                              <EyeIconOpen />
                            </span>
                          )}
                        </span>
                      </Button>
                    </div>

                    {/* Links for password recovery, visible only on small screens */}
                    <div className="small d-none d-sm-block mb-4">
                    <Paragraph className="small d-none d-sm-block mb-4">
                      {t('account.label.forgot')}{' '}
                      <Link
                        to={forgotUsername}
                        data-testid="forgotUsernameLink"
                        className="text-decoration-underline"
                        aria-label="Forgot username"
                      >
                        {t('account.label.forgotUsername')}
                      </Link>{' '}
                      {t('bookAppointment.labels.or')}{' '}
                      <Link
                        to={resetPassword}
                        data-testid="forgotPasswordLink"
                        className="text-decoration-underline"
                        aria-label="Forgot password"
                      >
                        {t('account.label.password')}
                      </Link>
                      </Paragraph>
                    </div>

                    {/* Button to submit the login form */}
                    <div className="text-md-center text-lg-start mb-2 pb-2">
                      {/* Button to submit the login form */}
                      <Button
                        type="submit"
                        dataTestId="signInButton"
                        variant={
                          isValid
                            ? 'primary_btn btn_block_mob'
                            : 'primary_btn btn_block_mob disabled_btn'
                        }
                      >
                        {t('account.label.login').toString()}{' '}
                      </Button>
                    </div>

                    {/* Horizontal line separating the form from the register link */}
                    <hr className="d-none d-lg-block mb-4" />

                    {/* Link to register page */}
                    <Paragraph
                      className="d-none d-lg-block mb-4 pt-1"
                      data-testid={"donthaveAccountButton"}
                    >
                      {t('account.label.notYetRegistered')}
                    </Paragraph>

                    {/* Button to navigate to the registration page */}
                    <div className="text-md-center text-lg-start mb-4">
                      <Button
                        dataTestId="registerNowButton"
                        type="button"
                        onClick={() => {
                          window.location.href = Navigate.registration;
                        }}
                        variant="secondary_btn btn_block_mob"
                      >
                        {t('account.label.registerNow')}
                        </Button>
                    </div>

                    {/* Links for password recovery, visible only on extra-small screens */}

                    <Paragraph  className="small d-block d-sm-none mb-4">
                      {t('account.label.forgot')}{' '}
                      <Link
                        to={forgotUsername}
                        data-testid="forgotUsernameLink"
                        className="text-decoration-underline"
                        aria-label="Forgot username"
                      >
                        {t('account.label.forgotUsername')}
                      </Link>{' '}
                      {t('bookAppointment.labels.or')}{' '}
                      <Link
                        to={resetPassword}
                        data-testid="forgotPasswordLink"
                        className="text-decoration-underline"
                        aria-label="Forgot password"
                      >
                        {t('account.label.password')}
                      </Link>
                    </Paragraph>
                  </form>
              </div>

              {/* Image container, visible only on large screens */}
              <div className="col-lg-7 col-md-6 col-sm-12 col-12 d-none d-lg-block">
                {/* Image for the sign-in page */}
                <img
                  data-testid="signInBannerImage"
                  src={ImageLogo}
                  className="img-fluid signin-banner-img pl-3"
                  alt=""
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
          {/* Horizontal line separating the main content from the footer */}
          <hr className="d-none d-lg-none d-md-block mb-0" />
        </div>

        {/* Footer section */}
        <footer className={`d-none d-lg-block d-md-block mt-auto ${!isAvMed() ? "pt-4" : ""}`}>
          {isAvMed() && <FooterNav /> }
          {/* Component to display the footer disclaimer */}
          <FooterDisclaimer />
        </footer>
      </div>
    </>
  );
};

export default SignInForm;
