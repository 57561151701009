import { useTranslation } from 'react-i18next';
import {
  Headings,
  ModalOverlay,
  SentaraLogo,
  MDLiveLogo,
  VirtualIcon,
  PhoneCallIcon,
  Loader
} from '@sentaraui/optimahealth_web';
import { VirtualVisitModalBox } from 'templates'; 
import { SSOKeys,  SERVICE_PHONE_NUMBER_BEHAVIORAL_HEALTH, SuperScript } from 'common';
import { useVirtualPopUpHooks } from './hooks/VirtualPopUpHooks';

interface BehavioralPopUpProps {
  closeModal: () => void;
  mdliveCopay?: string;
  sentaraCopay?: string;
}
function BehavioralPopUp({
    closeModal,
    mdliveCopay,
    sentaraCopay
}: BehavioralPopUpProps) {

    // Initialize translation hook
    const { t } = useTranslation();

    const behavioralPopUpHooks = useVirtualPopUpHooks();

    const sentaraDisclaimerList = [
      {text:t('NurseOnCall.sentaraContent.disclaimerNote2') , style:'font-size-14 mt-3'}!,
    ]

    const mdLiveDisclaimerList = [
        {superScript: <sup>{SuperScript.VIRTUAL_DISCLAIMER_TWO}</sup>,text:t('PrimaryCare.mdLiveContent.disclaimerNote1') , style:'font-size-14 mt-3'}!
    ]
  
    return (
      <>
        <ModalOverlay
          className="virtual-visit-selection"
          data-testid={'BehavioralSelectionId'}
          isOpen
          onClose={closeModal}
          overlayInfo="behavioral selection"
        >
          <div>
          <Headings
            className="text-start"
            level={2}
            text={t('virtualVisitCare.selectProviderPopup.title')}/>
          <div className="d-flex flex-column flex-lg-row gap-4 mt-2 justify-content-center">
            <div className="curved-box">
              <VirtualVisitModalBox
                logoImage={<SentaraLogo/>}
                title={t(
                  'PrimaryCare.sentaraContent.title'
                )}
                subtitle={t('behavioral.sentaraContent.label')!}
                badgeImage={<PhoneCallIcon/>}
                content={t(
                  'behavioral.sentaraContent.serviceContent'
                )}
                buttonLabel={t(
                  'behavioral.sentaraContent.buttonText'
                )}
                buttonTestId='sentaraCallButton'
                buttonOnClick={() => {
                    behavioralPopUpHooks.PhoneNumberLink({ phoneNumber: SERVICE_PHONE_NUMBER_BEHAVIORAL_HEALTH });
                }}
                displayBlock={true}
                minHeight={'235'}
                disclaimers={sentaraDisclaimerList}
              />
            </div>
            <div className="curved-box">
              <VirtualVisitModalBox
                logoImage={<MDLiveLogo/>}
                title={t('virtualVisitCare.selectProviderPopup.mdliveTitle')}
                costInfo={t('PrimaryCare.sentaraContent.costInfo')}
                badgeImage={<VirtualIcon/>}
                superTextTwo={SuperScript.VIRTUAL_DISCLAIMER_TWO}
                costValue= {mdliveCopay}
                content={t(
                  'behavioral.mdLiveContent.serviceContent'
                )}
                buttonLabel={t(
                  'virtualVisitCare.selectProviderPopup.scheduleAppointment'
                )}
                buttonTestId='scheduleMDliveAppointment'
                buttonOnClick={() => {
                  behavioralPopUpHooks.getMDliveSSOUrlFromAPI(SSOKeys.VirtualVisit);
                }}
                minHeight={'180'}
                disclaimers={mdLiveDisclaimerList}
              /> 
            </div>
          </div>
        </div>
        </ModalOverlay>
        {behavioralPopUpHooks.loading ? <Loader /> : null}
      </>
    );
  }
  
export default BehavioralPopUp;