import { useState } from 'react';
import {signIn, deleteEncryptedValue} from 'common'

// ApiWrapper is a custom hook that abstracts API calling logic
export const ApiWrapper = (anyfunc: any) => {
  // State variables to store data, API errors, and error messages
  const [data, setData] = useState(null);
  const [apiError, setApiError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState(null);

  // Function to make an API call
  const callApi = async (...params: any) => {
    setData(null);
    setErrorMessage(null);
    setApiError(false);
    try {

      // make API call to fetch some data
      const response = await anyfunc(...params);
      // Check if the API response contains data
      if (response?.data || response?.data == '') {
        setData(response?.data);
      }
      // If response has an errorCode, handle the error
      else if (response?.errorCode) {
        errorhandler(response?.errorCode);
      }
    } catch (error: any) {
      // Handle any error during the API call
      setErrorMessage(error);
      setApiError(true);
    }
  };

  // Function to handle different types of errors
  const errorhandler = (error: any) => {
    if (isUnAuthorizedError(error)) {
      unAuthorizedErrorHandler();
    } else {
      // Set error message and flag for other errors
      setErrorMessage(error);
      setApiError(true);
    }
  };

  // Return data, API error status, error message, and callApi function
  return { data, apiError, errorMessage, callApi };
};

export const isUnAuthorizedError = (error : string) =>{
  const unAuthorizedError = [401, 440];
  const unAuthError = unAuthorizedError.includes(Number(error));
  return unAuthError
};
 
export const unAuthorizedErrorHandler = () => {
    if (typeof window !== 'undefined') {
      localStorage.clear();
      deleteEncryptedValue();
      window.location.href = '/sign-in';
  }
};

export default ApiWrapper;
