import React, { useState, useEffect } from 'react';
import { ModalCustomStyle,
  NumbersChar,
  getMaxLength,
  setStrengthBarColor,
  FormTitles,
  handleKeyDownUsername
} from 'common';
import { useTranslation } from 'react-i18next';
import PasswordInfoOverlay from './PasswordInfoOverlay';
import UsernameInfoOverlay from './UsernameInfoOverlay';
import Modal from 'react-modal';
import {
  useUserName,
  useUserNameValidation,
  useUserPassword,
} from '@sentara/sentara-api-hooks-core';
import {
  ErrorMessage,
  Label,
  Button,
  InputField,
  GlobalError,
  Headings,
  Loader,
  HelpIcon
} from '@sentaraui/optimahealth_web';
import { useForm, FormProvider } from "react-hook-form";
import {
  ChangePassword,
  ConfirmPassword,
  PasswordStrengthMeter,
} from 'templates';


import {  PASSWORD_CRITERIA } from '../forgot-password/PasswordConstant';


interface FormData {
  [key: string]: string;
}

interface UsernameData {
  name: string;
  password: string;
  confirmPassword: string;
}

// Define types for your props and state
interface UsernameProps {
  formData: FormData[];
  page: number;
  setFormData: (response: any) => void;
  setPage: (response: any) => void;
  plantypes?: string;
}
const UsernamePassword: React.FC<UsernameProps> = ({
  formData,
  page,
  setFormData,
  setPage,
  plantypes,
}) => {
   const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      name: formData[3]?.name || '',
      password: formData[3]?.password || '',
      confirmPassword: formData[3]?.confirmPassword || ''
   }
   });
  const { handleSubmit,register, getValues, formState: { errors,isValid } } = methods;
  const { t } = useTranslation();
  const { RegisterUserName } = useUserName();
  const { RegisterUserNameValidation } = useUserNameValidation();
  const { userPassword } = useUserPassword();
  const [pwdStrength, setPwdStrength] = useState<number>(0);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isPasswordModal, setPasswordModalOpen] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [errorMessage1, setErrorMessage1] = useState<boolean>(false);
  const [errorPassword, setPasswordError] = useState<boolean>(false);
  const [value, setValue] = useState<any>('');
  const [loader, setLoader] = useState<boolean>(false);
  let errorUserName = t('activation.errors.userName');

  function openModal(e: { preventDefault: () => void; }) {
    e.preventDefault();
    setIsOpen(true);
  }

  function openModalPassword() {
    setPasswordModalOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  function closeModalPassword() {
    setPasswordModalOpen(false);
  }
  
  const renderErrorMessage = () => {    
    if (errorMessage) {
      return (
           <GlobalError variant="mb-3" dataTestId="userNameInputError">
                     {errorUserName}
          </GlobalError> 
      );
    }
    if (errorMessage1) {
      return (
        <GlobalError variant="mb-3" dataTestId="userNameInputError">
                     {t('activation.errors.userNameErrorMsg1')}
        </GlobalError> 
      );
    } 

    if (errorPassword) {
      return (
        <GlobalError variant="mb-3" dataTestId="passwordInputError">
                     {t('activation.errors.passwordrequired')}
        </GlobalError> 
      );
    }
    return null;
  };

   useEffect(() => {
    setStrengthBarColor(value, setPwdStrength);
  }, [value]);

  /** API call to validate the username*/
  const onSubmit = async (data:UsernameData) => { 
    usernameValidation(data);
    
  };
  /** API call to validate the username criteria*/
  const usernameValidation = async (data:UsernameData) => {
    setLoader(true);
    setFormData({ ...formData,[page]: { ...data} });
    const response = await RegisterUserName(data?.name);
   
    if (response?.errorCode) {
      errorHandle(response?.errorCode);
      return false;
    } else {
      setErrorMessage(false);
      setErrorMessage1(false);
      const registerUser_Response = await RegisterUserNameValidation(
        data.name
      );
      if (registerUser_Response?.errorCode) {
        errorHandle(registerUser_Response?.errorCode);
      } else {
        userPasswordValidation();
      }
    }
    return true;
  };

  /** Error Handling to diplay the error message when username entered wrong*/
  const errorHandle = (errorCode:string): void => {
    const checkValidUser = ['441', '400', '442', '445'];
    const checkUserExist  = ['449', '439','409'];
    const isValidUser: boolean = checkValidUser.includes(errorCode.toString());
    const isUserExist: boolean = checkUserExist.includes(errorCode.toString());
    if (isValidUser) {
      setErrorMessage(true);
      setErrorMessage1(false);
    } else if (isUserExist) {
      setErrorMessage(false);
      setErrorMessage1(true);
    } else {
      setErrorMessage(false);
      setErrorMessage1(false);
    }
    setLoader(false);
  };

  /** API call to validate the password criteria*/
  const userPasswordValidation = async () => {
    const data:any = {
      firstName: formData[1]?.firstName,
      lastName: formData[1]?.lastName,
      passwordNew: getValues('password'),
      userId: getValues('name')
    };

    const response = await userPassword(data);
    if (response?.errorCode) {
      handlePasswordError(response?.errorCode);
      setLoader(false);
    } else {
      setPage((currPage: number) => currPage + 1);
      setPasswordError(false);
      setLoader(false);
    }
  };
  /** API call to validate the password criteria*/
  const handlePasswordError = (errorCode: string): void => {
    const errorStatusCode: string[] = [
      '441',
      '400',
      '442',
      '445',
      '439',
      '443',
      '448',
      '422',
    ];
    const checkError = errorStatusCode.includes(errorCode.toString());
   if (checkError) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  };

   // Handle key down event
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Space') {
      e.preventDefault();
    }
  };

  const inputStrengthClass = pwdStrength in PASSWORD_CRITERIA ? `strength-bar-${PASSWORD_CRITERIA[pwdStrength]}` : ''

  return (
    <>
      <FormProvider {...methods}>
      <form data-testid="submit_form" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      {loader && <Loader />}
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12">
            <div aria-live="polite">
              {renderErrorMessage()}
              {FormTitles[page] !== '' && (
              <>
                <span className="visually-hidden me-1">
                  {t('accessibility.message.registrationCurrentPage')} {[page + 1]}
                </span>
                <div className="pb-4 mb-4">
                  <Headings
                    dataTestId="registrationHeading"
                    level={1}
                    text={t(FormTitles[page])}
                  />
                </div>
              </>
            )}
            </div>
            <div className="mb-3 pos-rel">
              <Label
                className="pb-1"
                dataTestId={'userNameInputLabel'}
                htmlFor="regUserName"
                >
                  {t('activation.label.userNameSmall')}
                </Label>
            
              <div className="d-flex align-items-center">
                <div className="pos-rel flex-1">
                <InputField
                  id="regUserName"
                  data-testid="userNameInput"
                  placeholder={t('activation.label.userNameSmallPlaceholder')}
                  maxLength={getMaxLength.userName}
                  className={`form-input ${
                    errors?.name ? 'error-border' : ''
                  }`}
                  {...register('name', { required: true })}
                  onKeyDown={handleKeyDownUsername}
                  aria-describedby="regUserName_error"
                />
                {errors?.name?.type === 'required' &&  (
                <div className="mt-1">
                  <ErrorMessage  data-testid={'confirmEmailInputError'}>{errorUserName}</ErrorMessage>
                </div>
                )}
                {errors?.name?.type === 'validate' &&  (
                <div className="mt-1">
                  <ErrorMessage data-testid={'confirmEmailInputError'}>{t('activation.errors.firstCharUserName')} </ErrorMessage>
                </div>
                )}
                </div>
               
                <div className="ms-2">
                  <Button
                    variant="border-0 bg-transparent"
                    data-testid="userNameInputHelpIcon"
                    onClick={openModal}
                  >
                    <HelpIcon />
                  </Button>
                </div>
              </div>
            </div>
            <div className="mb-3 pos-rel">
              <div className="d-flex align-items-center">
                <div className="pos-rel flex-1">
                  <ChangePassword
                        onKeyDown={onKeyDown}
                        setValue={setValue}
                        showPasswordPlaceholder={`${t(
                          'activation.label.passwordPlaceholder'
                        )}`}
                      />
                  
                </div>

                <div className="ms-2">
                  <Button
                    variant="border-0 bg-transparent"
                    data-testid="passwordInputHelpIcon"
                    onClick={(e: { preventDefault: () => void; }) => {
                      e.preventDefault();
                      openModalPassword();
                    }}
                  >
                    <HelpIcon />
                  </Button>
                </div>
              </div>
            </div>
            {/* Render PasswordStrengthMeter component */}
                <div className="pb-2">
                  <PasswordStrengthMeter
                    inputStrengthClass = {inputStrengthClass}
                  />
                </div>
            <div className="mb-3 pos-rel">
                 <div className="mb-3 pos-rel">
                  <ConfirmPassword
                    onKeyDown={onKeyDown}
                    showPasswordPlaceholder={`${t(
                          'activation.label.confirmPasswordPlaceholder'
                        )}`}
                  />
                </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row pt-4 pb-2">
          <div className="col-lg-auto col-md-auto col-sm12 col-12 d-grid gap-2 d-md-block mb-4">
            <Button
              dataTestId={'continueButton'}
              variant={
                isValid
                  ? 'primary_btn btn_block_mob'
                  : 'btn_block_mob disabled_btn'
              }
              type="submit"
            >{t('activation.label.continue')}</Button>
          </div>
          <div className="col-lg-auto col-md-auto col-sm12 col-12 d-grid gap-2 d-md-block mb-4">
            <Button
              dataTestId={'cancelButton'}
              variant={'secondary_btn btn_block_mob'}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.preventDefault();
                setFormData({
                  ...formData,
                  ...{
                    [page]: formData[page]
                      ? Object.keys(formData[page]).reduce((obj, item) => {
                          obj = { ...obj, [item]: '' };
                          return obj;
                        }, {})
                      : {},
                  },
                });
                setPage((currPage: number) => currPage - 1);
              }}
            >{t('activation.label.cancel')}</Button>
          </div>
        </div>
      </form>
      </FormProvider>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        data-testid="userNameInputModal"
        contentLabel="Username Information Overlay"
        style={ModalCustomStyle}
      >
        <div className="w-member-overlay" data-testid="userNameInputModalModal">
          <div className="text-right">
            <Button
              type="button"
              data-testid="userNameInputModalClose"
              className="btn-close"
              onClick={closeModal}
              aria-label="Close"
            >
              <span aria-hidden="true" className="visually-hidden">
                {t('appointments.buttonLableTwoClose')}
              </span>
            </Button>
          </div>
          <UsernameInfoOverlay />
        </div>
      </Modal>

      <Modal
        isOpen={isPasswordModal}
        onRequestClose={closeModalPassword}
        data-userid="passwordInputModal"
        contentLabel="Password Information Overlay"
        style={ModalCustomStyle}
      >
        <div className="w-member-overlay" data-testid="passwordInputModalModal">
          <div className="text-right">
            <Button
              type="button"
              data-testid="passwordInputModalClose"
              className="btn-close"
              onClick={closeModalPassword}
              aria-label="Close"
            >
              <span aria-hidden="true" className="visually-hidden">
                {t('appointments.buttonLableTwoClose')}
              </span>
            </Button>
          </div>
          <PasswordInfoOverlay />
        </div>
      </Modal>
    </>
  );
};

export default UsernamePassword;
