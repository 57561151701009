import {
    Button,
    Headings,
    Paragraph
  } from '@sentaraui/optimahealth_web';

interface Disclaimer {
    superScript?: React.ReactElement;  
    style: string;
    text: string;
}

interface VirtualVisitModalBoxProps {
    logoImage?: React.ReactElement | null,   
    title: string,
    subtitle?: string,
    costInfo?: string | null,
    badgeImage: React.ReactElement,
    content: string,
    disclaimers?: Disclaimer[],
    buttonLabel: string,
    buttonTestId: string,
    minHeight: string,
    buttonOnClick: () => void,
    costValue?: string,
    superTextOne?: string,
    superTextTwo?: string,
    superTextThree?: string,
    displayBlock?:boolean

}

function VirtualVisitModalBox({
    logoImage,
    title,
    subtitle,
    costInfo,
    badgeImage,
    content,
    disclaimers,
    buttonLabel,
    buttonTestId,
    minHeight,
    buttonOnClick,
    superTextOne,
    superTextTwo,
    superTextThree,
    costValue,
    displayBlock = false
}: VirtualVisitModalBoxProps) {
  return (
    <div className="d-flex flex-column justify-content-between">
      {logoImage ? <span className="d-flex flex-column justify-content-between virtual-visit-icon-style pt-3">
        {logoImage}
      </span> : null}
      <div className="p-md-4 pb-1 p-2">
        <div style = {{minHeight : `${minHeight}px`}}>
          <Headings level={3} variant={'semiBold'} text={`${title}`} className="d-inline-block"/>
          {superTextOne ? <sup className='ms-1'>{superTextOne}</sup> : null}
          <div>
            {subtitle ? <Headings level={5} text={subtitle} className="d-inline-block"/> : null}
            {costInfo ? <Headings level={5} text={costInfo} className={!displayBlock ? "d-inline-block" : ""}/> : null}
            {costValue ? <>
            <span className="ps-2">
                {costValue}</span> 
            {superTextTwo ? <sup className='ms-1'>{superTextTwo}</sup> : null}
            </>
            : null}
          </div>
          <span className="d-inline-block my-2">{badgeImage}</span>
          <Paragraph>{content}{superTextThree? <sup>{superTextThree}</sup> : null}</Paragraph>
        </div>
        <Button
          className="btn w-100 mt-1 text-nowrap text-truncate"
          dataTestId={buttonTestId}
          children={buttonLabel}
          variant={'primary_btn'}
          onClick={() => {
            buttonOnClick();
          }}
        />
        <ul className="labels-list">
          {disclaimers && disclaimers.length > 0
            ? disclaimers.map((label, index) => (
                <li key={index} className={label.style}>
                  {label.superScript}{''}{label.text} 
                </li>
              ))
            : null}
        </ul>
      </div>
    </div>
  );
}

export default VirtualVisitModalBox;